<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Reset Password -->

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-end justify-content-end p-0 bg-container"
      >
        <b-img
          fluid
          :src="imgUrl"
          alt="Forgot password V2"
          style="width: 86.9%;"
        />
      </b-col>
      <!-- /Left Text-->
      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Reset Password
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                class="reset-btn"
                type="submit"
                variant="primary"
                block
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>

          <!-- /Reset Password v1 -->
        </b-col>
        <b-card-text class="font-small-1 login-footer">
          ©{{ currentYear }} Circle Of Care. All Rights Reserved. Powered by <b-link
            href="https://www.motivit.com"
            target="_blank"
          >MotivIT</b-link>
        </b-card-text>
      </b-col>
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BImg, BForm, BInputGroupAppend, BFormGroup, BInputGroup, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
// import axios from '@axios'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      email: '',
      token: '',
      sideImg: require('@/assets/images/pages/forgot-reset-coc.png'),

      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      currentYear: new Date().getFullYear(),
      validLink: 0,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.$swal.showLoading()
    /* Get the token/key and email in URL */
    const uri = window.location.href.split('?')
    const param = uri[1].split('&')
    this.email = param[1].split('=')
    this.email = decodeURIComponent(this.email[1])

    this.token = param[0].split('=')
    this.checkToken()
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal.showLoading()
          this.checkValidLink()
        }
      })
    },
    checkToken() {
      useJwt.axiosIns.post(`${this.$apiUrl2}auth`, {
        email: this.email,
        token: this.token[1],
        action: 'check_token',
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          this.validLink = response.data.response.data
          this.$swal.close()
          if (this.validLink !== 1) {
            this.$swal({
              title: 'Invalid Link!',
              html: 'Please create a new Reset Password Link',
              icon: 'error',
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-secondary pop-up-btn w-25',
              },
              allowOutsideClick: false,
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                window.location.replace('/')
              }
            })
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Operation Failed!',
            text: 'Please Try Again',
            allowOutsideClick: false,
            icon: 'error',
            customClass: {
              confirmButton: 'pop-up-btn w-25',
            },
            buttonsStyling: false,
          })
        })
    },
    changePassword() {
      useJwt.axiosIns.post(`${this.$apiUrl2}auth`, {
        email: this.email.toString(),
        new_pass: this.password,
        action: 'change_pass',
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          const res = response.data.response.data
          if (res === 1) {
            this.$swal({
              title: 'Success!',
              text: 'Your new password has been successfully saved.',
              icon: 'success',
              allowOutsideClick: false,
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-secondary pop-up-btn w-25',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                window.location.replace('/')
              }
            })
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Operation Failed!',
            text: 'Please Try Again',
            allowOutsideClick: false,
            icon: 'error',
            customClass: {
              confirmButton: 'pop-up-btn w-25',
            },
            buttonsStyling: false,
          })
        })
    },
    checkValidLink() {
      if (this.validLink !== 0) {
        this.changePassword()
      } else {
        this.$swal.close()
        this.$swal({
          title: 'Invalid Link!',
          html: 'Please create a new Reset Password Link',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-secondary pop-up-btn w-25',
          },
          allowOutsideClick: false,
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            window.location.replace('/')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
